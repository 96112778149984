import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, publishReplay, refCount} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  private apiUrl = environment.apiUrl;
  private clinics: Observable<any> = this.http.get(`${this.apiUrl}/companies`)
    .pipe(
      map(user => user),
      publishReplay(1),
      refCount());
  
  private clinicsForMainPage: Observable<any> = this.http.get(`${this.apiUrl}/companies/clinics`)
  .pipe(
    map(user => user),
    publishReplay(1),
    refCount());    

  private clinicDoctors = new BehaviorSubject(null);
  getClinicDoctorsList = this.clinicDoctors.asObservable();

  private selectedDoctor = new BehaviorSubject(null);
  getSelectedDoctor = this.selectedDoctor.asObservable();

  constructor(private http: HttpClient) {
  }

  getClinicOrders(companyId: number, params?: HttpParams): Observable<any> {
    return this.http.get(`${this.apiUrl}/companies/${companyId}/orders`, {params})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getClinicDoctors(companyId: number, params?: HttpParams): Observable<any> {
    return this.http.get(`${this.apiUrl}/companies/${companyId}/doctors`, {params})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getClinics() {
    return this.clinics;
  }

  getClinicsForMainPage() {
    return this.clinicsForMainPage
  }

  getAllClinicDoctors(companyId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/companies/${companyId}/doctors/all`)
      .pipe(
        map((response: any) => {
          this.clinicDoctors.next(response.data);
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  setClinicDoctor(doctor) {
    this.selectedDoctor.next(doctor);
  }

  addClinicDoctorSpecialty(companyId: number, doctorId, data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/companies/${companyId}/doctors/${doctorId}/user-specialty`, data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }


  removeDoctorSpecialty(companyId: number, doctorId, userSpecialtyId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/companies/${companyId}/doctors/${doctorId}/user-specialties/${userSpecialtyId}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getClinicDetails(companyId): Observable<any> {
    return this.http.get(`${this.apiUrl}/companies/${companyId}/info`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  addIban(iban: any, companyId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('iban', iban);
    return this.http.put(`${this.apiUrl}/companies/${companyId}/iban`, null, {params})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getOrderPatientDetails(companyId, orderId): Observable<any> {
    return this.http.get(`${this.apiUrl}/companies/${companyId}/orders/${orderId}/patient`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
